.r {
    display: flex;
    min-height: 100vh;
}

.r-left {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    /* border-top-right-radius: 61px; */

    background-color: #004D80;
    color: #ddd;
}

.r-left ul, h1, h2 , h3, h4 {
    margin-top: 10px;
    padding-bottom: 4px;
}

.r p {
    font-size: small;
    padding-left: 14px;
}

.r-left strong {
    font-weight: 700;
    font-size: 14px;
}

.r-left-wrapper {
    margin-top: 50px;
    margin-left: 40px;
    margin-right: 40px;
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    /* background-color: #c7e1ff; */
    max-width: 700px;
}

.r-title {
    font-size: 25px;
    color: white;
    margin-bottom: 25px;
}

.r-left h2 {
    color: #ccc;
}

.r-left h3 {
    color: white;
}

.r-left li {
    font-size: 12px;
}

.r-right {
    background-color: white;
    justify-content: end;
    min-width: 240px;
    min-height: 100%;
}

.r-right-wrapper {
    margin-top: 50px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #004D80;
}

.r-right-wrapper h4 {
    margin-top: 20px;
    font-size: 14px;
    padding-top: 10px;
}

.r-bottom-menu-container {
    margin-left: 20px;
    margin-right: 40px;
    margin-bottom: 40px;
    display: flex;
    max-height: 100px;
    /* background-color: rgba(127, 255, 242, 0.403); */
    justify-self: flex-start;
    align-self: flex-end;
    width: 90%;
    max-width: 700px;
}

.r-bottom-menu {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow: visible;

    /* background-color: aquamarine; */
    width: 100%;
    max-width: 700px;
}

.r-menu-item {
    margin-right: 5px;
    color:white;
    padding: 15px 25px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 18px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.r-menu-item:hover {
    background-color: #eee;
    color:#004d80;
    font-weight: 600;
}

.r-left-wrapper ul li {
    margin-top: 4px;
}

/* Project Card */

.r-card {
    margin-top: 14px;
    padding: 10px;
    display: grid;
    grid-template-columns: 100px minmax(200px, 90%);
    grid-template-rows: minmax(20px, auto) auto auto;
    gap: 10px;
    max-width: 640px;
    background-color: #356788;
    border-radius: 32px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.card-item {
    /* background-color: rgba(127, 255, 212, 0.54); */
    justify-content: center;
    overflow: hidden;
}

.r-card ul li {
    margin-top: -1px;
    list-style: square;
}

.r-card ul{
    margin-top: 0px;
    margin-left: -24px;
}

.r-card p{
    padding-right: 0px;
    min-height: fit-content;
}

.r-card-Img {
    
    max-height: auto;
    grid-row-start: 1;
    grid-row-end: 3;
}

.r-card-Img img {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    border-radius: 27px;
}

.r-card-title {
    align-self: start;
    line-height: 1.1;
}

.r strong {
    color: white;
}

.r-bottomCenter {
    flex: 1;
    font-size: 10px;
    text-align: center;
    align-self: center;
    justify-self: flex-end;
}

.r-mobile {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 20px;
    padding: 20px;
    color: #004D80;
    font-size: 12px;
    min-height: 46vh;
}

@media screen and (max-width: 480px) {
    .r {
        flex-direction: column;
    }

    .r-title {
        margin-bottom: 0px;
    }

    .r-title h2 {
        font-size: 30px;
    }

    .r-left {
        border-top-right-radius: 0px;
    }
    .r-left-wrapper {
        margin-top: 20px;
    }
    .r-right {
        display: none;
    }
    .r-mobile {
        display: flex;
    }
    .r-menu-item {
        margin-left: 40px;
    }
    .r li, .r-mobile li, .r-card {
        margin-left: -20px;
    }
    .r-card li {
        margin-left: -10px;
    }
}

@media screen and (min-width: 481px) {
    .r {
        flex-direction: row;
    }
    .r-mobile {
        display: none;
    }
}

