* {
  margin: 0;
  box-sizing: border-box;
}


body {
  
  font-family: 'Poppins', Arial, Verdana, 'Droid Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
}

/* .app-wrapper {
  
} */


a {
 text-decoration: none;
}

a:visited {
  color: black;
}

/* ul {
  padding-left: inherit;
} */
