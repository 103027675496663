.i {
    display: flex;
    min-height: 100vh;
    background-color: white;
}

/*Left*/

.i-desktop-menu-container {
    display: inline-block;
    max-height: 100px;
    /* background-color: rgb(127, 255, 242);  */
    justify-self: flex-start;
    align-self: flex-start;
}

.i-desktop-menu {
    margin-top: 40px;
    margin-left: 25px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow: visible;
    position: absolute;
    /* background-color: aquamarine; */
    width: 100%;
    max-width: 700px;
}

.d-menu-item {
    margin-right: 16px;
    color:rgb(61, 61, 61);
    padding: 14px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 18px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.d-menu-item:hover {
    background-color: #eee;
    color:#004d80;
    font-weight: 600;
}

.i-mobile {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 40px;
}

.i-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.i-left-wrapper {
    margin-left: 40px;
    margin-right: 40px;
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    /* background-color: #c7e1ff; */
    max-width: 700px;
}

.i-intro {
    font-size: 30px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 0px;
    /* background-color: #ffc7ed; */
}

.i-name {
    font-size: 43px;
    line-height: 97%;
    padding: 16px 0;
    /* background-color: #ffc7ed; */
}

.i-title-main {
    font-size: 20px;
    font-weight: 700;
    color: #777;
    margin-top: 10px;
    /* background-color: #ffc7ed; */
}

.i-skills {
    margin-top: 4px;
    height: 50px;
    overflow: hidden;
    margin-bottom: 10px;
}

.i-skills-wrapper {
    height: 100%;
    animation: move 28s ease-in-out infinite alternate;
}

@keyframes move {
    30% {
        transform: translateY(-50px);
    }
    40% {
        transform: translateY(-100px);
    }
    51% {
        transform: translateY(-150px);
    }
    68% {
        transform: translateY(-200px);
    }
    85% {
        transform: translateY(-250px);
    }
    100% {
        transform: translateY(-300px);
    }
}

.i-skills-item {
    color: #004D80;
    height: 50px;
    font-size: 12px;
    font-weight: 500;
}

.i-desc {
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 14px;
}

@media screen and (min-width: 721px) {
    .i-skills-item {
      font-size: 14px;
    }
}

@media screen and (max-width: 720px) {
    .i-skills-item {
        font-size: 11px;
    }
}

/* Right */

.i-right {
    display: flex;
    background-color: #004D80;

    /* border-bottom-left-radius: 61px; */

    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    min-width: 240px;
    max-width: 240px;
}

.i-offer-mobile {
    display: flex;
    background-color: #004D80;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-height: 50vh;
}

#contactEmail {
    margin-bottom: 20px;
}

.i-offer-mobile #contactEmail {
    text-align: center;
    margin-top: -40px;
    margin-bottom: 20px;
}

.i-offer-mobile a {
    color: white;
}

.i-right-wrapper {
    display: flex;
    justify-content: flex-end;
    /* background-color: #50235a; */
}

.i-contact {
    align-self: center;
    padding: 25px;
    font-size: 12px;
    font-weight: 400;
    color: #ddd;
    /* background-color: #50235a; */
}

.i-contact ul {
    margin-top: 4px;
    padding-left: 20px;
}

.i-me-img {
    margin-left: -100px;
    margin-top: -10px;
    width: 300px;
    height: auto;

    border-bottom-left-radius: 90%;
    /* border-bottom-left-radius: 90px; */

    z-index: 3;
}

.i-me-img-mobile {
    width: 160px;
    border-bottom-left-radius: 100%;
    height: auto;
    margin-bottom: -120px;
}

.i-right a {
    color: white;
}

.i-right a:visited {
    color: white;
}

strong {
    font-weight: 900;
}

.i-right strong {
    font-weight: 700;
}

@media screen and (max-width: 480px) {
    .i {
        flex-direction: column;
    }
    .i-right {
        display: none;
    }
    .i-desktop-menu-container {
        display: none;
    }
}

@media screen and (min-width: 481px) {
    .i {
        flex-direction: row;
    }
    .i-mobile {
        display: none;
    }
    .i-offer-mobile {
        display: none;
    }
}

.p-menu1{
    margin-top: 20px;
    position: relative;
    display: inline-block;
 }

 /* Hamburger */
 .hamburger1 {
   height: 30px;
   display: -ms-grid;
   display: grid;
   grid-template-rows: repeat(3, 1fr);
   justify-items: left;
   z-index: 120;
 }

 .hamburger1 div {
   background-color: rgb(61, 61, 61);
   position: relative;
   width: 40px;
   height: 5px;
   margin-top: 7px;
   -webkit-transition: all 0.2s ease-in-out;
   transition: all 0.2s ease-in-out;
 }

 #toggle1 {
   display: none;
 }

 #toggle1:checked + .hamburger1 .top {
   -webkit-transform: rotate(-45deg);
           transform: rotate(-45deg);
   margin-top: 22.5px;
 }

 #toggle1:checked + .hamburger1 .meat {
   -webkit-transform: rotate(45deg);
           transform: rotate(45deg);
   margin-top: -5px;
 }

 #toggle1:checked + .hamburger1 .bottom {
   -webkit-transform: scale(0);
           transform: scale(0);
 }

 #toggle1:checked ~ .menu1 {
   height: auto;
 }


 /* Menu */
 .menu1 {
    box-sizing: border-box;
    max-width: 340px;
    border-radius: 34px;
    margin-top: 10px;
    position: absolute;
    display: flex;
    flex-direction: column;
    grid-template-rows: 1fr repeat(3, 1fr);
    grid-row-gap: 0px;
    padding: 0;
    left: 0;
    list-style: none;
    clear: both;
    text-align: center;
    height: 0px;
    overflow: hidden;
    transition: height .4s ease;
    z-index: 2;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
 }

 /* .menu1 a:first-child {
   margin-top: 40px;
 }

 .menu1 a:last-child {
   margin-bottom: 40px;
 } */

 .link1 {
   width: 100%;
   margin: 0;
   padding: 10px 90px;
   font: 400 25px 'Poppins';

   background-color: #004d80;
   color: white;
   border: solid 1px silver;
   border-radius: 25px;
 }

 .link1:hover {
   background-color: #fff;
   color: rgb(61, 61, 61);
   -webkit-transition: all 0.3s ease;
   transition: all 0.3s ease;
 }

